.bcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.blink-logo {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.blink-logo img {
  width: 200px;
}
.blink-logo h3 {
  font-size: 45px;
  font-weight: 800;
  color: #6054e4;
  text-align: center;
}

.blink-des {
  padding: 2rem;
}
.blink-des p {
  padding-left: 128px;
  padding-right: 128px;
  font-size: 19px;
  color: #555555;
}

.img-section-blink {
  /* padding: 4rem; */
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-top: 2rem;
  padding-bottom: 4rem;
}
.img-section-blink img {
  width: 350px;
  height: auto;
  /* padding: 4rem; */
}

.blinks-insights {
}

.blinks-carousel {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #ffa41c 0%, #ff1f00 100%);
}

.blinks-des {
  padding: 3rem;
}

.blinks-title p {
  color: #f7f6f6;
}
.blinks-title h3 {
  font-size: 22px;
  color: #f7f6f6;
}
