.solutions-container {
  padding: 30px;
  /* margin-top: 60px; */
}
.section-one {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.section-one-title {
  font-size: 30px;
  font-weight: 600;
  padding: 1rem;
  color: #6054e4;
}
.discription {
  padding: 1rem;
  color: #555555;
  padding-left: 7rem;
  padding-right: 7rem;
  font-size: 18px;
}
.section-one-tab-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-one-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 1rem;
}
.tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 2rem;
}
.tab-button {
  background-color: lightgray;
  padding: 5px;
  width: 120px;
  /* height: 50px; */
  border-radius: 0.5rem;
  font-size: 18px;
  font-weight: 500;
}
.tab-item {
  /* width: 1024px; */
  height: 649px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
}
.tab-button.active {
  background-color: #6054e4;
  color: #fff;
  border-radius: 0.5rem;
}
.tab-item-description {
  color: #2d2d2d;
  padding: 15px 140px;
  font-size: 19px;
  text-align: center;
}
.section-two {
  padding-top: 2rem;
}

.section-three {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.solution-container {
  /* padding: 2rem; */
  /* padding-left: 12rem;
  padding-right: 12rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.solution-title {
  padding: 2rem;
  font-size: 42px;
  font-weight: 800;
  text-align: center;
}
.solution-description {
  font-size: 19px;
  font-weight: 400;
  padding: 0 145px;
  color: #555555;
  text-align: center;
  line-height: 1.6;
  margin: 20px 0;
}

@media (max-width: 1024px) {
  .solution-description {
    padding: 0 80px; /* Reduce padding for medium screens */
  }
}

@media (max-width: 768px) {
  .solution-description {
    padding: 0 40px; /* Further reduce padding for smaller screens */
    font-size: 17px; /* Slightly smaller font for better readability */
  }
}

@media (max-width: 480px) {
  .solution-description {
    padding: 0 20px; /* Minimal padding for mobile devices */
    font-size: 16px; /* Smaller font for mobile screens */
  }
}


/* ------------- */
.business-box {
  padding: 5rem;
}
.business-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 600px;
}
.circle {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #6054e4 0%, #5c218a 100%);

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.phone-image {
  width: 50%;
  height: auto;
}

.card {
  width: 290px;
  height: 210px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  flex-direction: column;
  text-align: start;
  background: #ffffff;
  box-shadow: 0px 10px 20px 9px #00045c33;
  p {
    color: #555555;
    font-size: 14px;
  }
}
.card-title {
  font-size: 19px;
  padding: 5px;
}
.top-left {
  top: -35px;
  left: -30%;
}

.middle-left {
  top: 50%;
  left: -30%;
  transform: translateY(-50%);
}

.bottom-left {
  bottom: -6%;
  left: -30%;
}

.top-right {
  top: -35px;
  right: -30%;
}

.middle-right {
  top: 50%;
  right: -30%;
  transform: translateY(-50%);
}

.bottom-right {
  bottom: -6%;
  right: -30%;
}

.wrapper-title {
  font-size: 40px;
  font-weight: 800;
  line-height: 81.6px;
  text-align: center;
  color: #6054e4;
}

@media (max-width: 1024px) {
  .wrapper-title {
    font-size: 35px; /* Smaller font size for screens 1024px or smaller */
    line-height: 65.28px;
  }
}

@media (max-width: 768px) {
  .wrapper-title {
    font-size: 36px; /* Further reduce font size for smaller screens */
    line-height: 48.96px;
  }
}

@media (max-width: 480px) {
  .wrapper-title {
    font-size: 28px; /* Even smaller for mobile devices */
    line-height: 38px;
  }
}
.wrapper-description {
  font-size: 20px;
  font-weight: 400;
  line-height: 34.7px;
  color: #555555;
}

.wrapper-group-visuals{
  display: flex;
  justify-content: center;
}
/* ---4 */
.section-four {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9f9f9; /* Optional: Background color for better separation */
}

.section-four-content {
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.section-four-title {
  font-size: 30px;
  font-weight: bold;
  color: #6054e4; /* Adjust text color as needed */
  margin-bottom: 20px;
}

.section-four-description {
  font-size: 16px;
  color: #666; /* Adjust text color as needed */
  margin-bottom: 30px;
  line-height: 1.5; /* Adds spacing between lines for readability */
}

.section-four-images h4 {
  font-size: 18px;
  color: #444; /* Adjust text color as needed */
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.image-grid img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}