.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f0f4f8; /* Professional background color */
  padding-left: 135px;
    padding-right: 135px;
}

.carousel-images {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px; /* Adjust as needed */
  overflow: hidden;
  position: relative;
}

.carousel-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  transition: opacity 1s ease, transform 1s ease;
  transform: scale(0.8);
  padding-left: 15px;
    padding-right: 15px;
}

.carousel-image.active {
  opacity: 1;
  transform: scale(1);
  z-index: 1;
}

.carousel-image.inactive {
  opacity: 0.3;
  transform: scale(0.8);
  z-index: 0;
}

.carousel-description {
  margin-top: 20px;
  text-align: center;
  color: #333;
  font-size: 1.2em;
  opacity: 0;
  animation: fadeIn 1s forwards;
  min-height: 200px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
