.carousele-vert {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 500px; /* Fixed width for the carousel */
  height: auto; /* Height will be dynamic based on the image ratio */
  padding: 20px;
  box-sizing: border-box;
}
.side-pagination-vert {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 3rem;
}

.slides-container-vert {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 230px;
  /* padding: 0 4rem; */
}

.slide {
  border-radius: 0.5rem;
  width: 100%;
  height: auto;
  max-height: 100%; /* Prevents image from exceeding the carousel's height */
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.slide img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the slide area without stretching */
  border-radius: inherit;
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.arrow-up {
  top: 10%;
  left: 2rem;
  transform: rotate(-90deg);
}

.arrow-down {
  bottom: 10%;
  left: 2rem;
  transform: rotate(90deg);
}

.indicate-vert {
  display: flex;
  flex-direction: column;
  /* position: absolute; */
  /* left: 2rem; */
  /* bottom: 10%; */
}

.indicator-vert {
  background-color: #885cf7;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0.2rem 0;
  cursor: pointer;
}

.indicator-vert-inactive {
  background-color: rgb(250, 250, 250);
}

/* Additional styles */

.gradient-overlay-vert {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)); */
  pointer-events: none;
}

@media (max-width: 789px) {
  .carousele-vert {
    width: 100%; /* Full width on smaller screens */
    max-width: 500px;
  }
}

@media (min-width: 790px) {
  .carousele-vert {
    width: 100%;
    height: auto;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Rye&display=swap");

.font-fantasy {
  font-family: "Rye", serif;
  font-weight: 1200;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css?family=Playfair+Display");

.Product {
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.top-content .carousel-control-prev-icon {
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c593d8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.top-content .carousel-control-next-icon {
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c593d8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.boxShadow {
  box-shadow: 0 10px 30px 0 rgba(189, 189, 189, 0.3),
    0 10px 30px rgba(0, 0, 0, 0.08);
}
