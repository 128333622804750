.carousele {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 55px;
  box-sizing: border-box;
  padding-left: 135px;
  padding-right: 135px;
}

.slide {
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 7px #666; */
  width: 100%;
  height: 100%;
  opacity: 0;
  /* transition: opacity 1.5s ease-in-out; */
  /* animation:fade 8s infinite; */
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide.active {
  opacity: 1;
}

.slide img {
  max-width: 50%;
  max-height: 50%;
  object-fit: cover;
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 1rem;
  height: 1rem;
  color: white;
  border-radius: 50%;
}

.arrow:hover {
  cursor: pointer;
}

.arrow-left {
  left: 4rem;
}

.arrow-right {
  right: 4rem;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 2rem;
}

.indicator {
  background-color: #885cf7;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: rgb(250, 250, 250);
}

@media (max-width: 789px) {
  .headers {
    width: 1080px;
  }

  .carousel {
    width: 800px;
    height: 500px;
  }
}

@media (min-width: 790px) {
  .carousel {
    width: 100%;
    height: calc((100vw - 20px) * (210 / 500));
  }
}

@import url("https://fonts.googleapis.com/css2?family=Rye&display=swap");

.font-fantasy {
  font-family: "Rye", serif;
  font-weight: 1200;
  font-style: normal;
}

/* .gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2%; 
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  pointer-events: none;
} */

@import url("https://fonts.googleapis.com/css?family=Playfair+Display");

.Product {
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.top-content .carousel-control-prev-icon {
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c593d8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.top-content .carousel-control-next-icon {
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c593d8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.boxShadow {
  box-shadow: 0 10px 30px 0 rgba(189, 189, 189, 0.3),
    0 10px 30px rgba(0, 0, 0, 0.08);
}
