@keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  .animate-slide {
    display: flex;
    flex-wrap: nowrap;
    animation: slide 20s linear infinite;
  }
  
  .partner-icons-container {
    overflow: hidden;
    white-space: nowrap;
  }
  