.hospitality-carousel {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #00df67 0%, #0487ff 100%);
}

.hospitality-des {
  padding: 3rem;
}

.hospitality-title p {
  color: #f7f6f6;
}
.hospitality-title h3 {
  font-size: 22px;
  color: #f7f6f6;
}
.vivre-logo {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
}
/* SARA Insights */
