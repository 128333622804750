/* BlogPage.css */
.blog-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family:serif!important;
}

.blog-header {
  text-align: center;
  margin-bottom: 30px;
  font-family:sans-serif !important;
}

.blog-header h1 {
  font-size: 2.5rem;
  color: #333;
  font-family:serif!important;
}

.blog-content h3 {
  font-size: 1.75rem;
  margin-top: 30px;
  color: #444;
  font-family:serif!important;
}

.blog-content p {
  line-height: 1.6;
  margin-bottom: 15px;
  color: #555;
  font-family:serif!important;
}

.blog-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

.blog-content ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 25px; /* Space for the custom bullet */
}

.blog-content ul li::before {
  content: "";
  position: absolute;
  left: -16px;
  top: 25%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: rgb(5, 174, 240); /* Custom bullet color */
  border-radius: 50%;
}

.blog-content ul li strong {
  color: #222;
}

.blog-container p {
  text-align: justify;
}

.intro {
  margin-bottom: 30px;
}

.conclusion {
  margin-top: 40px;
}

.heroImage {
  width: "100%";
  height: "auto";
  margin-bottom: "2rem";
}
.blog-footer {
  margin-top: 40px;
  padding-top: 20px;
  text-align: center;
  font-size: 1rem;
  color: #999;
}


.author-info {
  display: flex;
  align-items: center;
  margin: 50px 0 0px;
}

.author-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.author-details {
  max-width: 400px;
}

.author-name {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  color: #9e83aa;
}

.author-bio {
  font-size: 1em;
  margin: 0;
  color: #444;
}
