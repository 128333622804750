/* Container Styles */
.sara-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
}

/* First Section Styles */
.sara-first {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
}

/* Logo Styles */
.logo {
  width: 200px;
}

/* Title Styles */
.sara-title {
  margin-top: 3rem;
}

.sara-title h3 {
  font-size: 45px;
  font-weight: 800;
  color: #6054e4;
  text-align: center;
}

/* Description Styles */
.sara-des {
  padding: 2rem;
}

.sara-des p {
  padding: 0 145px;
  font-size: 19px;
  color: #555555;
  text-align: center; /* Center align text for better presentation */
}

/* Image Section Styles */
.img-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
}

.img-section img {
  width: 100%;
  max-width: 550px;
  height: auto;
  padding: 2rem;
  box-sizing: border-box; /* Ensure padding does not affect the width */
}

/* What Makes S.A.R.A Special Styles */
.w-m-s-p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  margin-bottom: 10px;
}

.sara-title2 {
  font-size: 32px;
  color: #6054e4;
  text-align: center;
}

/* Features Section Styles */
.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px; /* Reduced gap for better fit */
  padding: 30px;
}

.features img {
  width: 100%;
  height: auto;
}

/* Carousel Styles */
.sara-carousel {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #d763ff 0%, #da239c 100%),
              linear-gradient(180deg, rgba(224, 205, 255, 0.1) 0%, #e0cdff 100%);
}

/* Customer Insights Styles */
.sara-cus-title p {
  color: #f7f6f6;
}

.sara-cus-title h3 {
  font-size: 22px;
  color: #f7f6f6;
}

.sara-cus-des {
  padding: 3rem;
}

/* Blog Cards */
.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
}

.sara-styled-card {
  width: 390px;
  height: 260px;
  position: relative;
  overflow: visible; /* Allow the image to overflow */
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top:8rem;
  cursor: pointer;
}

.overlay-image {
  width: 100%;
  max-width: 450px;
  height: auto;
  position: absolute;
  top: -140px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px 8px 0 0;
}

.card-content {
  padding: 20px;
  margin-top: 6rem;
}

.title {
  margin: 0;
  font-size: 18px;
  color: #6054E4;
}

.description {
  color: #555;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.author {
  font-size: 12px;
  color: #888;
}

/* Responsive Adjustments for Text and Containers */
@media (max-width: 600px) {
  .sara-des p {
    padding: 0 1rem; /* Adjust padding for smaller screens */
    font-size: 16px;
  }
  
  .img-section img {
    width: 100%;
    padding: 1rem;
  }

  .features {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
