.cutomers-container {
  padding: 5rem;
  margin-top: 90px;
  /* Ellipse 9 */
}
.single-image {
  /* background: linear-gradient(180deg, #6054E4 0%, #5C218A 200%), #D9D9D9; */
  border-radius: 24px;
  /* background-image: url("../../assets//Customers/Ellipse.png"); */
}
.customers-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  h3 {
    font-size: 30px;
    font-weight: 700;
    color: #6054e4;
  }
  p {
    padding: 1rem;
    font-size: 19px;
    color: #555555;
  }
}
.carousel-cust {
  margin-top: 20px;
}
.c-title {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #6054e4;
}
.feature-partner {
  display: flex;
  justify-content: center;
  /* gap: 10px; */
  flex-direction: column;
}
.feature {
  display: flex;
  overflow: scroll;
  gap: 10px;
}
.feature-title {
  padding: 2rem;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #6054e4;
}
.partner-title h3 {
  font-size: 28px;
  font-weight: 500;
  color: #6054e4;
}

.partner {
  padding-top: 60px;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partnrs-logo {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  img {
    margin: 2rem;
  }
}
