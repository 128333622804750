.wcontainer {
  /* padding-top: 90px !important; */
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper-group-visuals {
  position: relative;
  width: 100%;
}
.hero-image {
  width: 100%;
  height: auto;
}
.hero-Des {
  margin-top: 15px;
}
.text-overlay {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}
.text-overlay h3 {
  margin: 0;
  font-size: 30px;
  font-weight: 800;
}
.text-overlay p {
  /* margin-top: 20px; */
  font-size: 16px;
}

.mission-container {
  /* position: relative; */
  width: 100%;
  /* display: flex;
    flex-direction: column;
    align-items: center; */
}
.wrapper-title {
  font-size: 60px;
  font-weight: 800;
  line-height: 81.6px;
  text-align: center;
  color: #6054e4;
}
.wrapper-description {
  font-size: 20px;
  font-weight: 400;
  line-height: 34.7px;
  color: #555555;
}
.mission {
  text-align: center;
  font-size: 30px;
  color: #6054e4;
  font-weight: 500;
  padding: 35px;
}
.group {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(126, 71, 226, 0.4) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem;
  padding-left: 7rem;
  padding-right: 7rem;
}
.g {
  width: 190px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.g-title {
  font-size: 28px;
  color: #6054e4;
  font-weight: 600;
  border-radius: 50%;
  height: 85px;
  width: 85px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 -4px 6px rgba(0, 0, 0, 0.1) inset;
}
.g p {
  margin-top: 15px;
  font-size: 20px;
  color: #6054e4;
  font-weight: 700;
}
.book-demo {
  margin-bottom: 10px;
}
.demo-btn {
  padding: 12px 24px;
  /* background-color: #007bff; */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  background: linear-gradient(180deg, #1983ff 0%, #1b17ff 100%);
  box-shadow: 0px 10px 20px 9px rgba(0, 4, 92, 0.2);
  border-radius: 16px;
  font-weight: 600;
}
.demo-btn:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
  .demo-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .demo-btn {
    padding: 8px 16px;
    font-size: 0.8rem;
  }

  .text-overlay {
    bottom: 10px;
  }
}
