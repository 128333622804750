.automation-blog-container {
  font-family: serif !important;
  line-height: 1.7;
  color: #333;
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.automation-blog-header {
  text-align: center;
  margin-bottom: 40px;
  background-color: #2196f3;
  padding: 25px;
  border-radius: 8px 8px 0 0;
}

.automation-blog-header h1 {
  font-size: 2.4em;
  color: #ffffff;
  margin: 0;
  font-family: serif !important;
}

.automation-blog-content {
  padding: 20px;
}

.automation-blog-content h2 {
  color: #333;
  font-size: 2.2em;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 2px solid #2196f3;
  padding-bottom: 10px;
  font-family: serif !important;
}

.automation-blog-content p {
  margin-bottom: 20px;
  font-size: 1.15em;
  color: #666;
  font-family: serif !important;
}

.automation-blog-content ul {
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 1.15em;
}

.automation-blog-content ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
}

.automation-blog-footer {
  text-align: center;
  margin-top: 40px;
  padding: 15px 0;
  background-color: #2196f3;
  color: white;
  border-radius: 0 0 8px 8px;
  font-size: 1em;
}


.author-info {
  display: flex;
  align-items: center;
  margin: 50px 0 0px;
  max-width: 400px;
}

.author-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.author-details {
  max-width: 400px;
}

.author-name {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  color: #9e83aa;
}

.author-bio {
  font-size: 1em;
  margin: 0;
  color: #444;
}
