.ott-frontend {
  /* background-imasge: url("../../assets/frontend-applications.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 205px !important;
  padding-bottom: 175px !important;
  background: linear-gradient(180deg, #6054e4 0%, #5c218a 100%);
}
.ott-frontend {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.pf-logos {
  padding: 0px 0px 50px;
}
/* .animate-text {
margin-right: 5px;
animation:text 1s ;


} */

/* @keyframes text {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} */
.container {
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
p {
  padding-top: 0.8em;
  word-break: break-word;
  max-width: 100%;
  color: #344a5e;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.56;
}

h1 {
  position: relative;
  margin: 0;
  color: var(--color-heading);
  font-family: proxima-nova, Proxima Nova, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 71px;
}
h2 {
  /* position: relative; */
  margin: 0;
  color: var(--color-heading);
  font-family: proxima-nova, Proxima Nova, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 40px;
}

.block-page-header-general .inner-content p {
  padding-top: 0.8em;
  font-style: normal;
  /* font-weight: 300; */
  font-size: 20px;
  line-height: 25px;
  color: var(--color);
  word-break: break-word;
  max-width: 100%;
}

.component-buttons {
  display: inline-block;
  padding: 12px 20px;
  border: 0 solid transparent;
  background: none;
  /* text-align: center; */
  -webkit-mask: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzI4IiBoZWlnaHQ9IjQ3MyIgdmlld0JveD0iMCAwIDcyOCA0NzMiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03MjcuMjkxIDBIMC4xMzI4MTJMMC4xMzI4MTMgNDcyLjMwNkg2MjIuN0w3MjcuMjkxIDMxOS41NjlWMFoiLz4KPC9zdmc+Cg==)
    0 0 / 100% 100% no-repeat;
  /* mask: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzI4IiBoZWlnaHQ9IjQ3MyIgdmlld0JveD0iMCAwIDcyOCA0NzMiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03MjcuMjkxIDBIMC4xMzI4MTJMMC4xMzI4MTMgNDcyLjMwNkg2MjIuN0w3MjcuMjkxIDMxOS41NjlWMFoiLz4KPC9zdmc+Cg==) 0 0 / 100% 100% no-repeat; */
  /* border-radius: 0; */
  /* font-weight: 700; */
}
.component-buttons.btn-blue {
  background: #0092d3;
  color: #fff;
}
