ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
  }

  li::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: #3498db; /* You can change the color */
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }


  .chat-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    background: #fff;
    border: 1px solid #ccc;
}

.chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
}

.person-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.chat-header-text {
    margin-left: 10px;
}

.close-icon {
    cursor: pointer;
}

.chat-body {
    padding: 10px;
}

form {
    display: flex;
    flex-direction: column;
}

input,
textarea,
button {
    margin-bottom: 10px;
}

.enlarge-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
}


.animate-slide-in-left {
    transform: translateX(-100%);
    animation: slideInLeft 1s ease-in-out forwards;
  }
  
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .hexagon {
    position: relative;
    width: 200px;
    height: 190.48px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  



  .bg-custom-gradient{
    background: linear-gradient(to top, rgb(218, 126, 95) 0%, rgb(235 200 148) 90%);
  
  }
  
  
  
  
  
  
  