.res-continer {
  margin-top: 90px;
}
.res-section1 {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.res-title h3 {
  font-size: 30px;
  font-weight: 700;
  color: #6054e4;
}
.res-description p {
  font-size: 19px;
  color: #555555;
  padding: 1rem;
}
.form-carousel {
  text-align: center;
  /* background: linear-gradient(180deg, #6054e4 0%, #5c218a 100%); */
  background: linear-gradient(180deg, #54c5e4 0%, #3f96ef 100%);
  border-radius: 5px;
}
.form-carousel h3 {
  font-size: 24px;
  font-weight: 400;
  color: white;
  padding: 1rem;
}

.para {
  color: white;
  display: flex;
  flex-direction: row;
  width: 745px;
  overflow: hidden;
  margin: 20px auto;
}

.res-section2 {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.recent-blog {
  padding: 20px;
}
.recent-blog h3 {
  font-size: 28px;
  padding-left: 2rem;
  color: #6054e4;
}

.press {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #ada8df; */
  flex-direction: column;
}
.press-title {
  font-size: 30px;
  font-weight: 700;
  color: #6054e4;
}
.press-description p {
  /* font-size: 19px; */
  color: #555555;
  padding: 1rem;
}

.release-container {
  /* width: 100%; */
  max-width: 1024;
  /* height: 600px; */
  background-color: #f3f1ff;
  padding: 2rem;
  border-radius: 10px;
}
.first-release {
  display: flex;
  padding: 1rem;
  gap: 5rem;
}
.first-release-title {
  color: #6054e4;
  font-weight: 600;
}
.first-release-description p {
  color: #555555;
  font-size: 14px;
}
.readmore-btn {
  color: #555555;
}
.divider {
  border: 1px solid #6054e4;
  flex: none;
  order: 1;
  flex-grow: 0;
}
