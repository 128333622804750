.vivre-main {
  margin-top: 100px;
  /* padding: 4rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vivre-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.vivre-logo {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.vivre-logo img {
  width: 200px;
}
.vivre-logo h3 {
  font-size: 45px;
  font-weight: 800;
  color: #6054e4;
  text-align: center;
}

.vivre-des {
  padding: 2rem;
}
.vivre-des p {
  padding: 0 145px;
  font-size: 19px;
  color: #555555;
  text-align: center;
}

.cus-carousel {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #ff0c0c 0%, #205fff 100%);
}
.cus-title p {
  color: #f7f6f6;
}
.cus-title h3 {
  font-size: 22px;
  color: #f7f6f6;
}

.cus-des {
  padding: 3rem;
}
/* SARA Insights */
