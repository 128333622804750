.home-footer {
  height: 380px;
  width: 100%;
  /* background-image: url("../../assets//products/Rectangle.png"); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
background: linear-gradient(180deg, #6054E4 2.39%, #5C218A 74.47%), #D9D9D9;


}

.triangle-background {
  width: 100%;
  height: 500px; 
  background: white;
  position: relative;
  border: none;
}

.triangle-background::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5E48CE;
  clip-path: polygon(50% 95%, 100% 15%, 100% 100%, 0% 100%, 0% 15%);
}

/* .triangle {
  position: relative;
  width: 100%;
  height: 0; */
  /* padding-bottom: 50%; */
/* } */

/* .triangle::before {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 0;
  height: 0;
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  border-top: 9vh solid white;
} */

.social-icons {
  display: flex;
  gap: 40px;
  margin-top: 15px;

  width: 100%;
  justify-content: center;
  img{
    cursor: pointer;
  }
}
.socialicon {
  width: 46px;
  height: 46px;
}
.divider {
  border-bottom: 1px solid rgb(255, 255, 255);
  line-height: 1.5;
}

.nav-i {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  /* padding-left: 10rem;
    padding-right: 10rem; */
  gap: 4rem;
  color: white;
}
.nav-i h5 {
  cursor: pointer;
}
.nav-wrapper {
  padding-top: 2.5rem;
  /* padding-left: 15rem;
    padding-right: 15rem;  */
}
.contacts {
  padding: 1rem 3rem 0rem 3rem;
  display: flex;
  gap: 25px;
}
.email {
  display: flex;
}
p {
  color: white;
  padding: 0;
}
.location {
  display: flex;
}
.div {
  width: 100%;
  padding-left: 12rem;
  padding-right: 12rem;
}
.terms-conditions {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  p {
    padding: 1rem;
    cursor: pointer;
  }
  span {
    color: aliceblue;
  }
}
