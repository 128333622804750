.wcontainer {
  padding-top: 100px !important;
}
.wrapper-title {
  font-size: 60px;
  font-weight: 800;
  line-height: 81.6px;
  text-align: center;
  color: #6054e4;
}
.wrapper-description {
  font-size: 20px;
  font-weight: 400;
  line-height: 34.7px;
  color: #555555;
}
.mission {
  text-align: center;
  font-size: 30px;
  color: #6054e4;
  font-weight: 500;
  padding: 10px;
}
.group {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(126, 71, 226, 0.4) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5rem;
}
.g {
  width: 175px;
  text-align: center;
}

.g-title {
  font-size: 28px;
  color: #555555;
  font-weight: 600;
}

.history-section {
  padding: 4rem;
  display: flex;
  flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  h3 {
    font-size: 28px;
    font-weight: 500;
    color: #6054e4;
    text-align: center;
  }
  p {
    margin-top: 1rem;
    color: #555555;
  }
}
.history-info {
  /* width: 50%; */
  align-items: center;

  p {
    font-size: 19px;
  }
}

/* --------------------- */

.container {
  position: relative;
  width: 80%;
  height: 420px;
  display: flex;
  justify-content: center;
}

.red-line {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: red;
}

.vertical-line {
  position: absolute;
  width: 2px;
  background-color: black;
}

.vertical-line.top {
  height: 30px;
  bottom: 100%;
}

.vertical-line.bottom {
  height: 30px;
  top: 100%;
}

.vertical-line:nth-child(1) {
  left: 10%;
}

.vertical-line:nth-child(2) {
  left: 20%;
}

.vertical-line:nth-child(3) {
  left: 30%;
}

.vertical-line:nth-child(4) {
  left: 40%;
}

.vertical-line:nth-child(5) {
  left: 50%;
}

.vertical-line:nth-child(6) {
  left: 60%;
}

.bottom-asset {
  padding: 4rem;
}

.applynow {
  padding: 4rem;
}
.applybtn {
  cursor: pointer;
}

.history-visuals {
  /* display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 400px;
  margin-left: 15px;
  justify-content: center; */

  /* background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(126, 71, 226, 0.4) 0%,
    rgba(255, 255, 255, 0.4) 100%
  ); */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* padding: 2rem; */
  /* padding-left: 7rem;
  padding-right: 7rem; */
}

.about {
  width: 190px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-title {
  font-size: 28px;
  color: #6054e4;
  font-weight: 600;
  border-radius: 50%;
  height: 85px;
  width: 85px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 8.3), 0 26px 38px rgb(27 154 244) inset;
}
.about p {
  margin-top: 15px;
  font-size: 20px;
  color: #6054e4;
  font-weight: 700;
}
